import React, { useState, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ErrorMessage.css';
import Keyboard from "react-simple-keyboard";
import AppContext from "../AppContext/AppContext";
import WarningMessage from '../WarningMessage/WarningMessage';
import imageiconurl from "../../../assets/images/iconalert.png";
import axios from "axios";

const ErrorMessage = ({ show, message, onAccept, imageicon, labelButton = 'ACEPTAR', imgWaring = '', nobutton, onHelp}) => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [number, setNumber] = useState("");
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const globalContext = useContext(AppContext);
  const [shownew, setshownew] = useState(true);

  if (!show) {
    return null;
  };

  const enableKeyBoard = () => {
    setIsKeyboardVisible(true);
  };

  const disableKeyBoard = () => {
    setIsKeyboardVisible(false);
  };

  const handleSendPhoneNumber = async () => {
    console.log('Número de teléfono enviado:', number);
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem("tk");
      const data = { phone: number, id_bitacora: globalContext.id_bitacora, id_customer: globalContext.id_customer };
      console.log(data);
      const response = await axios.post(
        `${baseUrl}/help-user`,
        data,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      console.log('Response:', response.data);
      handleCloseModal();
      setShowErrorMessage(true);
      globalContext.setayuda(false);
      setNumber("");
      return response.data;
    } catch (error) {
      console.error('Error storing bitacora in help-user:', error);
    };
  };

  const onAcceptWarning = () => {    
    setShowErrorMessage(false);
    onAccept();
    setshownew(true);
  };

  const handleCloseModal = () => {
    setShowHelpModal(false);
  };

  const excludeFromLayout = {
    default: [
      "",
      "@",
      "'",
      ";",
      "=",
      "-",
      ".",
      ",",
      "\\",
      "{tab}",
      "{lock}",
      "{shift}",
      "/",
      "]",
      "[",
      ".com",
    ],
  };

  const display = {
    "{bksp}": "<",
    "{enter}": "↵",
    "{space}": "ESPACIO",
  };

  const onKeyPressKeyBoard = (button ) => {
    console.log("Button pressed", button);
    if (button === "{bksp}") {
      setNumber(prev => prev.slice(0, -1));
    } else if (button === "{enter}") {
      disableKeyBoard();
    } else {
      if (number.length <= 7) {
        const formattedInput = number + button;
        setNumber(formattedInput);
        console.log("Input changed", formattedInput);
        if (number.length === 7) {
          setIsValidNumber(true);
          console.log("final", formattedInput);
          disableKeyBoard();
        };
      } else {
        if (number.length === 8) {
          setIsValidNumber(true);
          console.log("final", number);
          disableKeyBoard();
        };
      };
    };
  };

  const handleHelpButtonClick = () => {
    setShowHelpModal(true);
    setshownew(false);
    if (onHelp) {
      onHelp(); // Llamar a la función para detener el temporizador
    }
  };

  return (
    <div className='error-overlay2'>
      {shownew && (
        <div className='error-content'>
          <img src={imageicon} alt="Alert" />
          <div className='error-message' dangerouslySetInnerHTML={{ __html: message }}/>
          {imgWaring !== '' && (
            <img src={imgWaring} alt="Alert" />
          )}
          <div>
            {!nobutton ? (
              <>
                <button className="error-button" onClick={onAccept}>{labelButton}</button>              
                {globalContext.ayuda && (
                  <button className="ayuda-button" onClick={() => {setShowHelpModal(true); setshownew(false);}}>AYUDA</button>
                )}
              </>
            ) : (
              <button className="ayuda-button" onClick={() => {setShowHelpModal(true); setshownew(false); handleHelpButtonClick();}}>AYUDA</button>
            )}
          </div>
        </div>
      )}

      <Modal show={showHelpModal} onHide={handleCloseModal} className="custom-modal1" backdropClassName="custom-modal1-backdrop" centered backdrop="static">
        <Modal.Header className="custom-modal1-header">
          <Modal.Title>Ayuda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5 className='span-contacto'><strong>Ingrese su número de teléfono</strong> para que nuestro equipo de soporte pueda contactarlo.</h5>
            <input type="tel" className="phone-input" placeholder="Ingresa tu número de celular" value={number} onFocus={enableKeyBoard} onChange={(e) => setNumber(e.target.value)} pattern="[0-9]*" maxLength={8} readOnly={false} />
          </div>
          {isKeyboardVisible && (
            <div>
              <Keyboard
                excludeFromLayout={excludeFromLayout}
                theme={"hg-theme-default myTheme1"}
                layoutName={"caps"}
                onKeyPress={onKeyPressKeyBoard}
                layout={{
                  caps: [
                    "1 2 3",
                    "4 5 6",
                    "7 8 9",
                    "{bksp} 0 {enter}"
                  ]
                }}
                display={display}
                buttonTheme={[
                  {
                    class: "hg-enter",
                    buttons: "{enter}"
                  },
                  {
                    class: "hg-button-bksp",
                    buttons: "{bksp}"
                  }
                ]}
              />
            </div>
          )}
          <Button variant="success" onClick={handleSendPhoneNumber} disabled={!isValidNumber}>
            Enviar
          </Button>
        </Modal.Body>
      </Modal>
      <WarningMessage show={showErrorMessage} message="Uno de nuestros representantes <strong>se pondrá en contacto<br />con usted,</strong> para proporcionarle la asistencia que necesita." onAccept={onAcceptWarning} imageicon={imageiconurl} imgWaring={imgWaring} />
    </div>
  );
};

export default ErrorMessage;